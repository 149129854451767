<template>
    <div id="privacy-page">
        <h1>Privacy Policy</h1>
        <p>
            We are committed to ensuring the privacy of your personal information, such as your name, e-mail address, 
            telephone number or other identifying information. Our site may contain links to other sites that are not 
            managed by Calgary Animal Save. The inclusion of a link to another site from Calgary Animal Save does not 
            imply any endorsement of that site's privacy policies, practices or content. Your dealings with these sites 
            are covered only by the privacy policies listed on those sites.
        </p>
        <h2>Consent</h2>
        <p>
            By using our website, you hereby consent to our Privacy Policy and agree to its terms
        </p>
        <h2>Why does Calgary Animal Save ask for my information? </h2>
        <p>
            In order to participate in some activities, you might be asked to provide some personal information. All personal 
            information you enter into any form on this website will be saved (except credit card details) so we can keep you informed about CAS actions/events. 
            Your information will never be shared or sold. Credit card details are never saved or read through CAS servers.
            Visitors can <a class="anchor-color" href="/explore#contact-us-container">contact us</a> at any time to have all of their personal information removed from our systems. 
        </p>
        <h2>How will Calgary Animal Save use the information I provide?</h2>
        <p>
            The personal information you provide, and the reasons why you provide it, will be explained at the point we ask you 
            to provide your personal information. You are never required to provide us with your personal information, however 
            it may prevent your participation in activities for which personal information is needed, such as the subscription 
            to the Calgary Animal Save campaign updates, events email list, and action email forms. If you contact us directly, we may receive information 
            about you such as your email address, name, and any other information you choose to provide.
        </p>
        <p>
            We use the information we collect in various ways, including to:
            <br>
            <ul>
                <li>Operate, maintain, improve and analyze how you use our website</li>
                <li>Provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                <li>Send you emails regarding new events, campaigns, or volunteer opportunities</li>
                <li>Plan and develop new campaigns, services, or community spaces</li>
            </ul>
        </p>
        <h2>Does Calgary Animal Save use cookies? </h2>
        <p>
            Yes, we use cookies for a variety of purposes. These cookies are used to store information including visitors' preferences, shopping cart items, 
            and cookie accept policies.
            If you are concerned about the use of cookies you may set your browser to 
            refuse the cookies our site attempts to send.
        </p>
        <h2>Does Calgary Animal Save share my personal information? </h2>
        <p>
            Calgary Animal Save does not share or sell any of your personal information, nor do we share or sell the information 
            about what activities or donations supporters have undertaken on our website. 
        </p>
        <h2>Can I change the information I have given to Calgary Animal Save?</h2>
        <p>
            You can update your account information by contacting us at <a class="anchor-color" href="mailto:info@calgaryanimalsave.com">info@calgaryanimalsave.com</a>.
        </p>
        <h2>How do I know my credit card information is secure?</h2>
        <p>
            Calgary Animal Save uses secure server software (SSL) that is the industry standard. It encrypts all of your personal information, 
            including credit card number, name and address, so that it cannot be read as the information travels over the Internet.
        </p>
        <h2>How can I have my picture removed from the website?</h2>
        <p>
            Photos and videos are taken at all Calgary Animal Save actions and events and are used as promotional materials for our website. 
            If you have a concern regarding a photo(s) on our website please contact us at <a class="anchor-color" href="mailto:info@calgaryanimalsave.com">info@calgaryanimalsave.com</a>.
        </p>
        <h2>Have more questions?</h2>
        <p>
            Please contact us at <a class="anchor-color" href="mailto:info@calgaryanimalsave.com">info@calgaryanimalsave.com</a>.
        </p>
    </div>
</template>

<script>
    export default {
        name: "Privacy"
    }
</script>

<style lang="scss" scoped>

    .anchor-color {
        color:#d57f1e;
    }

    #privacy-page {
        width: 100vw;
        padding: 10px 20px;
        background-color: white;
        min-height: calc(100vh - 140px);
        margin-top: 70px; //account for fixed nav-bar height
        margin-bottom: 70px; //account for absolute footer height
        box-shadow: 0 5px 15px  rgb(131, 131, 131);

        h1, h2 {
            color: #ce7304;
            font-family: "Helvetica";
            font-weight: normal;
        }

        h1 {
            margin-bottom: 6vh;
            margin-top: 20px;
        }

        h2 {
            margin-bottom: 1vh;
            margin-top: 6vh;
        }
    }

@media screen and (min-width: 600px) {
    #privacy-page {
        margin-bottom: 150px; //account for absolute footer height
        padding: 10px 30px;

        h2 {
            margin-top: 4vh;
        }

        h2, p {
            margin-left: 5px;
        }
    }
}

@media screen and (min-width: 1000px) {
    #privacy-page {
        max-width: 1350px;
        min-height: calc(100vh - 230px);
        margin-top: 80px; //account for fixed nav-bar height
        display: grid;
        padding: 10px 80px;

        h2 {
            margin-top: 2vh;
        }

        h2, p {
            margin-left: 10px;
        }
    }
}
</style>